<template>
    <div>
        <v-row justify="center">
            <v-dialog
              v-model="dialog"
              persistent
              max-width="900px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Contract Detail</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                   <h3 class="text-h5 lfont"><v-icon color="blue lighten-1">mdi-account</v-icon><span class="lfont text-primary">{{ employee_item.name }} {{ employee_item.surname }}</span> </h3><hr>
                   <v-simple-table class="dense">
                    <template v-slot:default>
                        <tr>
                            <th>#</th>
                            <th>{{ $t("employee_contract.table.contract_file") }}</th>
                            <th>{{ $t("employee_contract.table.start_date") }}</th>
                            <th>{{ $t("employee_contract.table.end_date") }}</th>
                            <th>{{ $t("employee_contract.table.remark") }}</th>
                        </tr>
                        <tbody>
                            <tr v-for="(item, index) in employee_item.contract_detail">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    <span v-if="item.contract_file">
                                      <a :href="item.contract_file" target="_blank"><v-icon color="drak lighten-1">mdi-file</v-icon></a>
                                    </span>
                                    <span v-else>No file</span>
                                </td>
                                <td>{{ item.from_date }}</td>
                                <td>{{ item.to_date }}</td>
                                <td>{{ item.remark }}</td>
                                <td>
                                  <div v-if="employee_item.contract_detail.length == (index + 1) && item.id">
                                    <v-btn icon @click="delete_employeee_contract(item.id)">
                                      <v-icon color="red lighten-1">mdi-trash-can</v-icon>
                                    </v-btn>
                                  </div>
                                  
                                </td>
                            </tr>
                        </tbody>
                    </template>
                  </v-simple-table>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="close"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
    </div>
</template>

<script>
    export default {
        props:{
            dialog:"",
            employee_item:""
        },
        methods: {
            close(){
                this.$emit('close');
            },
            delete_employeee_contract(id){
              this.$emit('delete');
              this.$axios
                    .delete(`company/employee/contract/${id}`)
                    .then((res) => {
                        this.$emit('success');
                        this.$notification.ShowErrors(
                            "top-right",
                            "success",
                            3000,
                            'Success'
                        );
                    })
                    .catch((error) => {
                        this.$emit('success');
                        if (error.response.status === 422) {
                            let obj = error.response.data.errors;
                            if (!obj) {
                                obj = error.response.data;
                                let res = "";
                                for (let [key, value] of Object.entries(obj)) {
                                    res = value;
                                }
                                this.$notification.ShowErrors("top-right", "danger", 3000, res);
                            } else {
                                for (let [key, value] of Object.entries(obj)) {
                                    this.server_errors[key] = value[0];
                                    this.$notification.ShowErrors(
                                        "top-right",
                                        "danger",
                                        3000,
                                        (this.server_errors[key] = value[0])
                                    );
                                }
                            }
                        }
                        if (error.response.status === 500) {
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                "server error"
                            );
                        }
                    });
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>