<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ $t("employee_contract.renew.title") }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <h3 class="text-h5 lfont"><v-icon color="blue lighten-1">mdi-account</v-icon><span
                                    class="lfont text-primary">{{ renew_item.name }} {{ renew_item.surname }}</span> </h3>
                            <hr>

                            <v-row class="my-2">
                                <v-col cols="12">
                                    <div class="choose-image">
                                        <div class="show-image">
                                            <i class="fas fa-file-pdf"></i>
                                            <input @change="PreviewPDF" type="file" class="input-file-image"
                                                accept="application/pdf" />
                                            <p>Resume File(PDF)</p>
                                            <embed class="embed-pdf" v-if="showPDF" :src="showPDF" type="application/pdf"
                                                accept="application/pdf" />
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" lg="6" sm="6" md="6">
                                    <label for="" class="lfont">{{ $t("employee_contract.renew.start_date") }}</label>
                                    <v-text-field v-model="renew_item.contract_from_date" outlined dense
                                        :disabled="true"></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6" sm="6" md="6">
                                    <label for="" class="lfont">{{ $t("employee_contract.renew.end_date") }}</label>
                                    <DatePicker class="date-time" @keydown.enter="focusNext" style="width: 100%" type="date"
                                        valueType="format" slot="activator" :append-to-body="true" v-model="to_date"
                                        :rules="nameRules" requried></DatePicker>
                                </v-col>
                            </v-row>


                            <label for="" class="lfont">{{ $t("employee_contract.renew.remark") }}</label>
                            <v-textarea solo name="input-7-4"></v-textarea>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="save_data">
                            Save
                        </v-btn>
                        <v-btn color="red darken-1" text @click="close">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    props: {
        dialog: "",
        renew_item: ""
    },
    data() {
        return {
            from_date: "",
            to_date: "",
            remark: "",
            contract_file: "",
            nameRules: [(v) => !!v || "ກະລຸນາປ້ອນ..."],
            showPDF: "",
            server_errors: {
                employee_id: '',
                from_date: '',
                to_date: '',
                remark: '',
                contract_file: ''
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        save_data() {
            this.$emit('close');
            this.$emit('save');
            let items = {
                employee_id: this.renew_item.id,
                from_date: this.renew_item.start_date,
                to_date: this.to_date,
                remark: this.remark,
                contract_file: this.contract_file
            };
            this.$axios
                .post(`company/employee/contract`, items)
                .then((res) => {
                    this.showPDF = ''
                    this.contract_file = ''
                    this.$emit('success');
                    this.$notification.ShowErrors(
                        "top-right",
                        "success",
                        3000,
                        'Success'
                    );
                })
                .catch((error) => {
                    this.$emit('success');
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                    if (error.response.status === 500) {
                        this.$notification.ShowErrors(
                            "top-right",
                            "danger",
                            3000,
                            "server error"
                        );
                    }
                });
        },
        focusNext(e) {
            const inputs = Array.from(
                e.target.form.querySelectorAll('input[type="text"]')
            );
            const index = inputs.indexOf(e.target);
            if (index < inputs.length) {
                inputs[index + 1].focus();
            }
        },
        PreviewPDF: function (event) {
            const input = event.target;
            const file = input.files[0];
            const fileType = file["type"];
            const validImageTypes = ["application/pdf"];
            if (!validImageTypes.includes(fileType)) {
                this.$notification.ShowErrors(
                    "top-right",
                    "danger",
                    3000,
                    "image must be PDF"
                );
            } else if (input.files && input.files[0]) {
                this.imagePDF = input.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.showPDF = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
                this.UploadPDF(this.imagePDF);
            }
        },

        UploadPDF(imagePDF) {
            this.$emit('save');
            let formData = new FormData();
            formData.append("imageFile", imagePDF);
            this.$axios.post(`upload-file`, formData).then((res) => {
                if (res.status === 200) {
                    this.contract_file = res.data.filename;
                    this.$emit('uploaded');
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped></style>