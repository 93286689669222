<template>
  <v-dialog v-model="modalUpdateEmployeeStatus" width="500" persistent @close="setModalUpdateEmployeeStatus(false)"
    transition="scroll-y-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title class="title-header-form ml-5">
          <span class="title-modal-delete">{{ $t("Employee.employee_delete.update_status") }}</span>
        </v-list-item-title>
        <v-btn icon depressed @click="setModalUpdateEmployeeStatus(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="section-footer-delete">
        <div class="btn-section">
          <v-btn class="btn-action cancel" @click="RecoverEmployee()" :loading="btnLoading">
            {{ $t("Employee.employee_delete.confirm") }}
          </v-btn>
          <v-btn class="btn-cancel lfont" outlined @click="setModalUpdateEmployeeStatus(false)">
            {{ $t("Employee.employee_delete.cancel") }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
    emp_id: "",
  },
  data() {
    return {
      btnLoading: false,
      modalUpdateEmployeeStatus: false,
    };
  },

  methods: {
    setModalUpdateEmployeeStatus(isVisible) {
      this.modalUpdateEmployeeStatus = isVisible;
      this.$emit("change", isVisible);
    },

    RecoverEmployee() {
      this.btnLoading = true;
      this.$axios
        .put(`company/recover/employee/deleted/${this.emp_id}`)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.setModalUpdateEmployeeStatus(false);
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.btnLoading = false;
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  watch: {
    visible(val) {
      this.modalUpdateEmployeeStatus = val;
    },
  },
};
</script>
  
<style scoped lang="scss">
.main-card-delete {
  height: 200px;
  color: #585858;

  .card-action-delete {
    padding-top: 30px;
  }
}

.section-footer-delete {
  position: absolute;
  bottom: 20px;
  right: 0;
}
</style>
  