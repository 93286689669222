<template>
  <div>
    <div class="tabs is-centered is-boxed">
      <ul>
        <li
          :class="{ 'is-active': activeIndex === index }"
          v-for="(tab, index) in tabsData"
          :key="index"
          @click="changeTabs(index)"
        >
          <a>
            <span class="icon is-small"
              ><i class="fal fa-clipboard-user"></i
            ></span>
            <span class="label-input" v-if="$i18n.locale === 'en'">{{
              tab.name_en
            }}</span>
            <span class="label-input" v-else>{{ tab.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="activeIndex === 0">
      <employeeActive />
    </div>
    <div v-if="activeIndex === 1">
      <employeeExit />
    </div>
    <div v-if="activeIndex === 2">
      <employeeDel />
    </div>
  </div>
</template>

<script>
import employeeActive from "@/views/MainCompany/Employee/index";
import employeeExit from "@/views/MainCompany/Employee/EmployeeExit";
import employeeDel from "@/views/MainCompany/Employee/employeeDel";

export default {
  components: {
    employeeActive,
    employeeExit,
    employeeDel,
  },
  data() {
    return {
      activeIndex: 0,
      tabsData: [
        {
          name_en: "Employee Active",
          name: "ພະນັກງານ",
        },
        {
          name_en: "Employee Exit",
          name: "ພະນັກງານອອກວຽກ",
        },
        {
          name_en: "Employee Deleted",
          name: "ພະນັກງານຖືກລຶບອອກ",
        },
      ],
    };
  },
  methods: {
    changeTabs(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped>
</style>
