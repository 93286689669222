<template>
  <v-dialog v-model="modalShowAllEmployeeItem" fullscreen persistent @close="setModalShowAllEmployeeItem(false)"
    transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right;">
        <v-btn icon depressed @click="setModalShowAllEmployeeItem(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="employee-profile">
        <div class="profile-header" style="position: relative">
          <v-row dense>
            <v-col cols="12" md="12" lg="12">
              <v-row dense>
                <v-col cols="2" md="2" lg="2" class="profile left">
                  <div class="btn-rollback" @click="rollback">
                    <span><i class="fas fa-chevron-left"></i></span> {{ $t("Employee.rollBack") }}
                  </div>
                  <div class="profile">
                    <img :src="employeeItem.urlProfile" alt="">
                  </div>
                </v-col>
                <v-col cols="5" md="5" lg="5" class="profile center">
                  <h1>{{ employeeItem.name }} {{ employeeItem.surname }}</h1>
                  <span class="employee-type">{{ employeeItem.employment_type }}</span>
                  <div class="employee-info">
                    <h4 class="position" v-if="employeeItem && employeeItem.joinPositions"
                      v-for="(position, index) in employeeItem.joinPositions" :key="index">
                      {{ position.name }} at <span v-if="employeeItem && employeeItem.joinCompany">{{
                        employeeItem.joinCompany.name }}</span>
                    </h4>
                    <div class="address" v-if="employeeItem && employeeItem.joinAdress">
                      <p><i class="fas fa-map-marker-alt"></i>{{ employeeItem.joinAdress.province_name }},
                        {{ employeeItem.joinAdress.district_name }}, {{ employeeItem.joinAdress.village }}</p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="5" md="5" lg="5" class="profile right">
                  <div class="employee-contact">
                    <ul>
                      <li id="facebook"><i class="fab fa-facebook-f"></i></li>
                      <li id="whatsapp"><i class="fab fa-whatsapp"></i></li>
                      <li id="email"><i class="fal fa-envelope-open-text"></i></li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="header-footer">
            <v-col cols="6" md="6" lg="6">
              <div class="salary" v-if="canManageSalary">
                <p>Base Salary</p>
                <h2 v-if="employeeItem && employeeItem.joinBaseSalary">
                  {{ $helpers.numberFormatter(employeeItem.joinBaseSalary.base_salary) }} Kip
                </h2>
              </div>
            </v-col>
            <v-col v-if="employeeItem.status === 'open'" class="card-footer user-status " cols="6" md="6" lg="6">
              <p><span><i class="fal fa-plus"></i></span>Active</p>
            </v-col>
            <v-col v-if="employeeItem.status === 'pending'" class="card-footer user-status pending-user" cols="6" md="6"
              lg="6">
              <p><span><i class="fal fa-plus"></i></span>Leave Soon</p>
            </v-col>
            <v-col v-if="employeeItem.status === 'close'" class="card-footer user-status close-user" cols="6" md="6"
              lg="6">
              <p><span><i class="fal fa-plus"></i></span>Exit</p>
            </v-col>

          </div>
        </div>
        <div class="profile-content">
          <v-row>
            <v-col cols="12" md="3" lg="3">
              <div class="card-employee-info">
                <h1><span><i class="fal fa-users"></i></span>Employee Info</h1>
                <div class="info-detail">
                  <table class="table table-view">
                    <tbody>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-key"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">ID</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.emp_number }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fas fa-male"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Gender</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.gender }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-user"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Name</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.name }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-user"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Surname</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.surname }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-calendar-alt"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Birth Day</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.birth_date }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-calendar-alt"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Start Date</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.start_date }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-calendar-alt"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Employment Type</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.employment_type }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-user"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Annual Bonus</p>
                              </div>
                              <div class="sub-title">
                                <i class="fas fa-circle"
                                  :style="{ color: employeeItem.is_annual_bonus === 1 ? 'green' : 'red' }"></i>
                                {{ employeeItem.is_annual_bonus === 1 ? 'active' : 'inactive' }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <v-col class="mt-5" style="padding: 0" cols="12" md="12" lg="12">
                <div class="card-employee-info">
                  <h1><span><i class="far fa-poll"></i></span>Job Title</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinJobtitle">
                    <table class="table table-view">
                      <tbody>
                        <tr>
                          <td>
                            <div class="contact-profile">
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">Title: {{ employeeItem.joinJobtitle.name }}</p>
                                </div>
                                <div class="sub-title">
                                  Budget: {{ $helpers.numberFormatter(employeeItem.joinJobtitle.budget) }}
                                </div>
                                <div class="sub-title" v-if="employeeItem && employeeItem.joinJobtitle.description">
                                  Decription: {{ employeeItem.joinJobtitle.description }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h1><span><i class="far fa-poll"></i></span>Education Level</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinEducationLevel">
                    <table class="table table-view">
                      <tbody>
                        <tr>
                          <td>
                            <div class="contact-profile">
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">Level: {{ employeeItem.joinEducationLevel.name }}</p>
                                </div>
                                <div class="sub-title">
                                  Budget: {{ $helpers.numberFormatter(employeeItem.joinEducationLevel.budget) }}
                                </div>
                                <div class="sub-title"
                                  v-if="employeeItem && employeeItem.joinEducationLevel.description">
                                  Decription: {{ employeeItem.joinEducationLevel.description }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="no-result" v-else>
                    <img :src="require('@/assets/defaultTable.png')" alt="">
                  </div>
                </div>
              </v-col>
              <v-col class="mt-5" style="padding: 0" cols="12" md="12" lg="12">
                <div class="card-employee-info">
                  <h1><span><i class="far fa-poll"></i></span>Employee Skill</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinSkills != 0">
                    <table class="table table-view">
                      <tbody>
                        <tr v-for="(skill, index) in employeeItem.joinSkills" :key="index">
                          <td>
                            <div class="contact-profile">
                              <div class="icon-contact-info">
                                <i class="far fa-poll"></i>
                              </div>
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">{{ skill.skill }}</p>
                                </div>
                                <div class="sub-title">
                                  {{ skill.rank }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="no-result" v-else>
                    <img :src="require('@/assets/defaultTable.png')" alt="">
                  </div>
                </div>
              </v-col>
            </v-col>
            <v-col cols="12" md="5" lg="5">
              <div class="card-employee-info">
                <h1><span><i class="fal fa-file-contract"></i></span>Contact Info</h1>
                <div class="info-detail">
                  <table class="table table-view" v-if="employeeItem && employeeItem.joinContact">
                    <tbody>
                      <tr v-for="(contact, index) in employeeItem.joinContact" :key="index">
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info" v-if="contact.contact_type_name === 'Facebook'">
                              <i class="fab fa-facebook"></i>
                            </div>
                            <div class="icon-contact-info" v-if="contact.contact_type_name === 'Email'">
                              <i class="fal fa-envelope"></i>
                            </div>
                            <div class="icon-contact-info" v-if="contact.contact_type_name === 'Phone'">
                              <i class="far fa-phone-alt"></i>
                            </div>

                            <div class="icon-contact-info" v-if="contact.contact_type_name === 'Whatsapp'">
                              <i class="fab fa-whatsapp"></i>
                            </div>

                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">{{ contact.contact_type_name }}</p>
                              </div>
                              <div class="sub-title">
                                {{ contact.value }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <v-col class="mt-5" cols="12" md="12" lg="12" style="padding: 0;">
                <div class="card-employee-info">
                  <h1><span><i class="fal fa-money-check-alt"></i></span>Benefit</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinBenefits != 0">
                    <table class="table table-view" v-if="employeeItem && employeeItem.joinBenefits">
                      <tbody>
                        <tr v-for="(benefit, index) in employeeItem.joinBenefits" :key="index">
                          <td>
                            <div class="contact-profile">
                              <div class="icon-contact-info">
                                <i class="fal fa-dollar-sign"></i>
                              </div>
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">{{ benefit.name }}</p>
                                </div>
                                <div class="sub-title">
                                  {{ benefit.value }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="no-result" v-else>
                    <img :src="require('@/assets/defaultTable.png')" alt="">
                  </div>
                </div>
              </v-col>
              <v-col class="mt-5" style="padding: 0" cols="12" md="12" lg="12">
                <div class="card-employee-info">
                  <h1><span><i class="fal fa-user-graduate"></i></span>Employee Educations</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinEducations != 0">
                    <table class="table table-view">
                      <tbody>
                        <tr v-for="(item, index) in employeeItem.joinEducations" :key="index">
                          <td>
                            <div class="contact-profile">
                              <div class="icon-contact-info">
                                <i class="fal fa-bookmark"></i>
                              </div>
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">{{ item.school }}</p>
                                </div>
                                <div class="sub-title">
                                  {{ item.description }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="no-result" v-else>
                    <img :src="require('@/assets/defaultTable.png')" alt="">
                  </div>
                </div>
              </v-col>
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <div class="card-employee-info">
                <h1><span><i class="fal fa-map-marked-alt"></i></span>Address Info</h1>
                <div class="info-detail">
                  <table class="table table-view">
                    <tbody v-if="employeeItem && employeeItem.joinAdress">
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-building"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Province</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.joinAdress.province_name }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-building"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">District</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.joinAdress.district_name }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="contact-profile">
                            <div class="icon-contact-info">
                              <i class="fal fa-building"></i>
                            </div>
                            <div class="title-contact">
                              <div class="main-title">
                                <p class="sm-text-title">Village</p>
                              </div>
                              <div class="sub-title">
                                {{ employeeItem.joinAdress.village }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <v-col style="padding: 0" class="mt-6" cols="12" md="12" lg="12">
                <div class="card-employee-info">
                  <h1><span><i class="far fa-tree-christmas"></i></span>Holiday</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinHolidays != 0">
                    <table class="table table-view" v-if="employeeItem && employeeItem.joinHolidays">
                      <tbody>
                        <tr v-for="(holiday, index) in employeeItem.joinHolidays" :key="index">
                          <td>
                            <div class="contact-profile">
                              <div class="icon-contact-info">
                                <i class="fal fa-calendar-day"></i>
                              </div>
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">{{ holiday.name }}</p>
                                </div>
                                <div class="sub-title">
                                  {{ holiday.amount }} ມື້
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="no-result" v-else>
                    <img :src="require('@/assets/defaultTable.png')" alt="">
                  </div>
                </div>
              </v-col>
              <v-col class="mt-5" style="padding: 0" cols="12" md="12" lg="12">
                <div class="card-employee-info">
                  <h1><span><i class="fad fa-poll-people"></i></span>Employee Experiences</h1>
                  <div class="info-detail" v-if="employeeItem && employeeItem.joinExperiences != 0">
                    <table class="table table-view">
                      <tbody v-if="employeeItem && employeeItem.joinExperiences">
                        <tr v-for="(item, index) in employeeItem.joinExperiences" :key="index">
                          <td>
                            <div class="contact-profile">
                              <div class="icon-contact-info">
                                <i class="fad fa-poll-people"></i>
                              </div>
                              <div class="title-contact">
                                <div class="main-title">
                                  <p class="sm-text-title">{{ item.company }}</p>
                                </div>
                                <div class="sub-title">
                                  {{ item.description }}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="no-result" v-else>
                    <img :src="require('@/assets/defaultTable.png')" alt="">
                  </div>
                </div>
              </v-col>
            </v-col>
            <v-col cols="12" md="12" lg="12" class="mt-6">
              <v-card elevation="2" class="reason-leave-soon" v-if="employeeItem && employeeItem.joinResigned">
                <h1>{{ $t("Employee.form.reason") }}</h1>
                <p> {{ $t("Employee.form.resigned_date") }} : <span>{{
                  employeeItem.joinResigned.resigned_date
                    }}</span>
                </p>
                <div class="reason-box mt-4">
                  {{ employeeItem.joinResigned.reason }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from 'vue';

export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    employeeItem: {},
    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      itemsEmployees: {},
      modalShowAllEmployeeItem: false,
    }
  },

  methods: {
    rollback() {
      this.setModalShowAllEmployeeItem(false)
    },
    setModalShowAllEmployeeItem(isVisible) {
      this.modalShowAllEmployeeItem = isVisible;
      this.$emit('change', isVisible);
    }
  },
  computed: {
    canManageSalary() {
      // Assuming user roles are stored in a Vuex store or a prop
      const userPermission = localStorage.getItem('userPermission');
      if (userPermission) {
        return userPermission.includes('managesalary');
      }
    },
  },
  watch: {
    visible(val) {
      this.modalShowAllEmployeeItem = val;
    },
    employeeItem(value) {
      console.log(value, 5555555)
    }
  },
}
</script>

<style scoped lang="scss">
.employee-profile {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;


  .profile-header {
    width: 100%;
    height: 280px;
    background-color: rgb(235, 246, 249);
    padding: 40px 15px;
    display: flex;
    position: relative;

    .profile {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .btn-rollback {
      width: 80px;
      height: 30px;
      background-color: $main-btn-color;
      font-family: $font-family;
      border-radius: 30px;
      position: absolute;
      top: 8px;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 300;
      text-transform: capitalize;
      cursor: pointer;

      span {
        margin-right: 5px;
      }
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .profile {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: #fff7f7;
        overflow: hidden;
        border: 2px solid #e7ebed;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

        }
      }
    }

    .center {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 1.6rem;
        font-family: $font-family;
        font-weight: 500;
      }

      .employee-type {
        background-color: rgba(254, 211, 48, 0.8);
        font-size: 12px;
        color: #FFFFFF;
        font-family: $font-family;
        width: max-content;
        padding: 2px 15px;
        border-radius: 30px;
        margin-top: 4px;

      }

      .employee-info {
        width: 100%;
        height: 100px;
        margin-top: 15px;

        .position {
          font-family: $font-family;
          font-size: 1.4rem;

          span {
            font-weight: 900;
          }
        }

        .address {
          width: 100%;
          height: 150px;
          display: flex;
          font-family: $font-family;
          font-size: 1.1rem;

          p {
            margin: 10px 0;

            i {
              font-size: 16px;
              margin-right: 5px;
              color: #e30505;
            }
          }
        }
      }
    }

    .right {
      display: flex;

      .employee-contact {
        ul {
          display: flex;

          li {
            width: 45px;
            height: 45px;
            background-color: red;
            margin: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            color: #FFFFFF;
            font-size: 1.3rem;
          }

          #facebook {
            background-color: #0978EA;

          }

          #whatsapp {
            background-color: #6CB669;
          }

          #email {
            background-color: #4A4A4A;
          }
        }
      }
    }

    .header-footer {
      width: 600px;
      height: 60px;
      background-color: #FFFFFF;
      border: 1px solid #eeeeee;
      position: absolute;
      bottom: 0;
      left: 50%;
      display: flex;

      .salary {
        font-family: $font-family;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px 0;

        p {
          font-size: 14px;
          margin: 0;
        }

        h2 {
          font-size: 22px;
        }
      }

      .card-footer {
        width: 100%;
        height: 60px;
        margin: 0;
        border-radius: 0;
        border: 1px solid #eeeeee;
      }

      .user-status {
        background-color: rgb(16, 163, 254);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family;
        color: #FFFFFF;
        font-size: 1.2rem;

        p {
          margin: 0;
        }

        i {
          margin-right: 8px;
        }
      }

      .pending-user {
        background-color: rgba(247, 183, 49, 1.0);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .close-user {
        background-color: rgb(238, 26, 58);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .profile-content {
    width: 100%;
    height: 100%;
    background-color: rgba(236, 240, 241, 0.3);
    padding: 40px;
    border-radius: 8px;
    display: flex;
    align-items: stretch;

    .card-employee-info {
      width: 100%;
      min-height: 300px;
      padding: 15px;
      background-color: #FFFFFF;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      border-radius: 4px;

      h1 {
        font-family: $font-family;
        padding: 10px 5px 5px 15px;
        font-size: 1.2rem;

        span {
          margin-right: 8px;
        }
      }

      .no-result {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;


        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
        }
      }


    }
  }

  .reason-leave-soon {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;

    h1 {
      font-family: $font-family;
      font-size: 1.4rem;
    }

    p {
      font-family: $font-family;

      span {
        background-color: rgba(252, 92, 101, 1.0);
        border-radius: 30px;
        padding: 2px 12px;
        font-size: 12px;
        color: #FFFFFF;
      }
    }

    .reason-box {
      width: 100%;
      min-height: 100px;
      display: block;
      border: 1px solid rgba(75, 101, 132, 0.8);
      border-radius: 8px;
      padding: 15px;
      font-family: $font-family;
    }


  }
}
</style>
