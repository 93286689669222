<template>
  <v-dialog
    v-model="modalUpdateEmployeeStatus"
    width="800"
    persistent
    @close="setModalUpdateEmployeeStatus(false)"
    transition="scroll-x-transition"
  >
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title
          style="font-size: 20px"
          class="title-header-form ml-5 mt-5"
        >
          {{ $t("Employee.form.changeStatus") }}
        </v-list-item-title>
        <v-btn icon depressed @click="setModalUpdateEmployeeStatus(false)">
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <div class="employee-status">
        <div class="status-content">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="12" class="mb-8">
                <label class="label-input">{{
                  $t("Employee.form.resigned_date")
                }}</label>
                <p class="error-validate">{{ server_errors.resigned_date }}</p>
                <DatePicker
                  class="date-time"
                  style="width: 100%"
                  type="date"
                  valueType="format"
                  slot="activator"
                  :append-to-body="true"
                  requried
                  persistent-hint
                  v-model="items.resigned_date"
                ></DatePicker>
              </v-col>

              <v-col cols="12" md="12" class="mb-8">
                <label class="label-input">{{
                  $t("Employee.form.reason")
                }}</label>
                <!-- <v-textarea
                  outlined
                  dense
                  v-model="items.reason"
                  :hint="`${server_errors.reason}`"
                  persistent-hint
                ></v-textarea> -->
                <v-select
                  :items="leaveStatus"
                  label=""
                  outlined
                  v-model="items.reason"
                ></v-select>
              </v-col>

              <v-col cols="12" md="12">
                <v-btn class="btn-save-change" @click="addEmployeeResign">
                  {{ $t("Employee.form.save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker,
  },
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: "mdi-close",
      type: String,
    },
    employeeId: {},
  },
  data() {
    return {
      leaveStatus: ["Resignation", "Termination", "End of Contract", "Other"],
      modalUpdateEmployeeStatus: false,
      items: {
        resigned_date: "",
        reason: "",
      },
      server_errors: {
        resigned_date: "",
        reason: "",
      },
    };
  },

  methods: {
    setModalUpdateEmployeeStatus(isVisible) {
      this.modalUpdateEmployeeStatus = isVisible;
      this.$emit("change", isVisible);
    },
    resetForm() {
      this.$refs.form.reset();
      this.items.resigned_date = "";
    },
    addEmployeeResign() {
      const item = {
        employee_id: this.employeeId,
        reason: this.items.reason,
        resigned_date: this.items.resigned_date,
      };
      this.$axios
        .post(`company/add/employee/resigned`, item)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("success");
            this.resetForm();
            this.setModalUpdateEmployeeStatus(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          this.btnLoading = false;
        });
    },
  },
  watch: {
    visible(val) {
      this.modalUpdateEmployeeStatus = val;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.employee-status {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .status-content {
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

