<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>
          {{ $t("Employee.title")
          }}<span class="small-title">({{ listEmployeeDel.length }}
            {{ $t("Employee.form.people") }})</span>
        </h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Employee.search')" v-model="searchItem" @keypress.enter="searchFilterItem">
        </v-text-field>
      </div>
      <!-- <div class="btn-new-create-action">
        <v-btn class="btn-create btn-export mr-4" @click="exportExcel()">
          <span
            ><i class="fal fa-file-export" style="margin-right: 4px"></i></span
          >Export Excel
        </v-btn>
      </div> -->
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table ref="employeeExport" class="table table-view" v-if="listEmployeeDel.length > 0">
          <thead>
            <tr>
              <th>
                {{ $t("Employee.table.Emp_ID") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.name") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.email") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.deleted_at") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listEmployeeDel" :key="idx">
              <td>{{ item.emp_number }}</td>
              <!-- <td @click="showAllEmployeeItem(item)" style="cursor: pointer">
                <div v-if="item.urlProfile" class="img-table-company">
                  <img :src="item.urlProfile" alt="" />
                </div>
                <div v-else class="demo-profile">
                  <i class="fal fa-user-alt"></i>
                </div>
              </td> -->
              <td class="employee-name">
                <div>
                  {{ item.name }} <span>{{ item.surname }}</span>
                  <div v-if="item.joinPositions" class="position">
                    <span>
                      {{ item.joinPositions[0].name }}
                      <span v-if="item.joinPositions.length > 1" class="length-position"></span>
                    </span>
                  </div>
                </div>
              </td>
              <td>
                {{ item.email }}
              </td>
              <td>
                {{ item.deleted_at }}
              </td>
              <td class="text-right">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="label-input" style="color: #0978ea" @click="onChangeEmployeeRecover(item.id)" outlined
                      v-bind="attrs" v-on="on">
                      <i class="fas fa-repeat"></i>
                      {{ $t("Employee.employee_delete.recover") }}
                    </v-btn>
                  </template>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>

        <defaultTableNoResult v-else />
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchEmployee">
        </Pagination>

        <showAllEmployeeItem :visible="modalShowAllEmployeeItem" :employeeItem="employee"
          @change="(val) => (modalShowAllEmployeeItem = val)" />
        <modalRecoverEmployee :visible="modalUpdateEmployeeStatus" :emp_id="emp_id" @success="fetchEmployee"
          @change="(val) => (modalUpdateEmployeeStatus = val)" />

        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import showAllEmployeeItem from "@/views/MainCompany/Employee/showAllEmployeeItem";
import modalRecoverEmployee from "@/components/employee/modal/modalRecoverEmployee";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    showAllEmployeeItem,
    modalRecoverEmployee,
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      modalShowAllEmployeeItem: false,
      modalUpdateEmployeeStatus: false,
      employee: {},
      emp_id: "",

      listEmployeeDel: [],
      isLoading: true,
      btnLoading: false,
    };
  },
  methods: {
    exportExcel() {
      this.$axios
        .get(`export/employee/turnOver/to/excel`, { responseType: "blob" })
        .then((res) => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "turn over employee.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        });
    },
    onChangeEmployeeRecover(emp_id) {
      this.emp_id = emp_id;
      this.modalUpdateEmployeeStatus = true;
    },

    showAllEmployeeItem(employeeId) {
      this.employee = {
        ...this.filterEmployee(employeeId.id),
      };
      this.modalShowAllEmployeeItem = true;
    },
    filterEmployee(employeeId) {
      return (
        this.listEmployeeDel.filter((item) => {
          return item.id == employeeId;
        })[0] || {}
      );
    },

    searchFilterItem() {
      this.pagination.current_page = 1;
      this.fetchEmployee();
    },
    fetchEmployee() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/employee/deleted`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeDel = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeDel.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch(() => { });
    },
  },
  created() {
    this.fetchEmployee();
  },
};
</script>

<style scoped lang="scss">
.fullTime {
  display: flex;
  align-items: center;

  i {
    color: #70f7bd;
    font-size: 8px;
    padding: 0 5px;
  }
}

.partTime {
  display: flex;
  align-items: center;

  i {
    color: #f5b556;
    font-size: 8px;
    padding: 0 5px;
  }
}

.employee-name {
  font-size: 16px;
  font-family: $font-family;
  font-weight: bold;
  color: rgb(0, 0, 0, 0.8);

  span {
    margin: 0 2px;
  }
}

.employee-position {
  font-size: 12px;
  font-family: $font-family;
  font-weight: lighter;
  color: #919191;

  i {
    padding: 0 5px;
  }
}

.position {
  span {
    position: relative;
    border-radius: 30px;
    font-size: 14px;
    font-family: $font-family;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #606060;
    font-weight: 300;

    .length-position {
      position: absolute;
      font-size: 12px;
      padding: 0.5px 6px;
      right: -35px;
      top: 0px;
      color: white;
      background-color: rgba(30, 144, 255, 0.4);
      cursor: pointer;
      transition: ease-out 0.2s;
    }

    .length-position:hover {
      transform: translateY(-10%);
    }
  }
}

.close-company {
  background-color: red;
  color: #ffffff;
  padding: 4px 18px;
  border: 30px;
}
</style>
